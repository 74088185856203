import { inject } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from 'app/modules/shared/authentication/authentication.service';

export const authenticationInterceptor = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authenticationService = inject(AuthenticationService);
    const unauthorized = 401;
    const forbidden = 403;

    if (authenticationService.isAuthenticated)
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${authenticationService.accessToken}`,
            },
        });

    return next(request).pipe(
        catchError((error) => {
            if (error instanceof HttpErrorResponse && (error.status === unauthorized || error.status === forbidden)) {
                authenticationService.signOut();
                location.reload();
            }

            return throwError(() => error);
        })
    );
};
