import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

import { ApiResponse } from '../api/api-response.type';
import { AccessToken } from './access-token.type';
import { AuthenticationCredentials } from 'app/modules/shared/authentication/authentication-credentials.type';
import { ResetPasswordToken } from 'app/modules/authentication/reset-password/reset-password-token.type';
import { ApiBaseService } from 'app/modules/shared/api/api-base.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends ApiBaseService implements OnDestroy {
    private readonly _accessTokenSubject = new BehaviorSubject<string>(null);
    private readonly _accessTokenKey = 'AQUATRACK_ACCESS_TOKEN';
    private readonly _persistenceKey = 'AQUATRACK_PERSISTENCE';
    private readonly _persistenceValue = 'ENABLED';
    private _token = null;

    public constructor() {
        super();

        this.restoreFromStorage();
    }

    private get storage(): Storage {
        return this.enablePersistence ? localStorage : sessionStorage;
    }

    private restoreFromStorage(): void {
        const accessToken = this.storage.getItem(this._accessTokenKey);

        if (this.isNotExpired(accessToken)) {
            this._accessTokenSubject.next(accessToken);
        } else {
            this.storage.removeItem(this._accessTokenKey);
        }
    }

    public setAccessToken(accessToken: string): void {
        this._token = null;
        this._accessTokenSubject.next(accessToken);
        this.storage.setItem(this._accessTokenKey, accessToken);
    }

    private removeAccessToken(): void {
        this._token = null;
        this._accessTokenSubject.next(null);
        this.storage.removeItem(this._accessTokenKey);
    }

    private isNotExpired(token: string): boolean {
        try {
            return token && jwtDecode(token).exp > Date.now() / 1000;
        } catch (exception) {
            console.error(exception);
        }
        return false;
    }

    public get accessToken(): string {
        return this._accessTokenSubject.getValue();
    }

    public get accessToken$(): Observable<string> {
        return this._accessTokenSubject.asObservable();
    }

    public get enablePersistence(): boolean {
        return localStorage.getItem(this._persistenceKey) === this._persistenceValue;
    }

    public set enablePersistence(enabled: boolean) {
        if (enabled) {
            localStorage.setItem(this._persistenceKey, this._persistenceValue);
        } else {
            localStorage.removeItem(this._persistenceKey);
            localStorage.removeItem(this._accessTokenKey);
        }
    }

    public get isAuthenticated(): boolean {
        return this.isNotExpired(this._accessTokenSubject.value);
    }

    public isAuthorized(claim: string): boolean {
        if (!this.isAuthenticated) return false;
        if (!this._token) this._token = jwtDecode(this._accessTokenSubject.value);
        return this._token.securityClaims.includes(claim);
    }

    public signIn(credentials: AuthenticationCredentials): Observable<boolean> {
        return this._httpClient.post(`${this.baseUrl}/auth/login`, credentials).pipe(
            map((response: ApiResponse<AccessToken>) => {
                this.setAccessToken(response.data.accessToken);
                return true;
            })
        );
    }

    public signInUsingGoogleToken(googleToken: string): Observable<boolean> {
        return this._httpClient.post(`${this.baseUrl}/auth/googlelogin`, `=${googleToken}`, { headers: this.defaultFormHeaders }).pipe(
            map((response: ApiResponse<AccessToken>) => {
                this.setAccessToken(response.data.accessToken);
                return true;
            })
        );
    }

    public changePassword(email: string, password: string, newPassword: string): Observable<boolean> {
        return this._httpClient.post(`${this.baseUrl}/auth/changepassword`, { email, password, newPassword }).pipe(
            map((response: ApiResponse<AccessToken>) => {
                this.setAccessToken(response.data.accessToken);
                return true;
            })
        );
    }

    public signOut(): Observable<boolean> {
        this.removeAccessToken();
        return of(true);
    }

    public unlockSession(credentials: { email: string; password: string }): Observable<unknown> {
        return this._httpClient.post(`${this.baseUrl}/auth/unlock-session`, credentials);
    }

    public resetPassword(resetPasswordToken: ResetPasswordToken): Observable<unknown> {
        return this._httpClient.post(`${this.baseUrl}/auth/resetpassword`, resetPasswordToken);
    }

    public forgotPassword(email: string): Observable<unknown> {
        return this._httpClient.post(`${this.baseUrl}/auth/sendpasswordresetlink?newsite=true`, `=${email}`, { headers: this.defaultFormHeaders });
    }

    public ngOnDestroy(): void {
        if (this._accessTokenSubject) {
            this._accessTokenSubject.unsubscribe();
        }
    }
}
