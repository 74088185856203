import { forkJoin } from 'rxjs';
import { inject } from '@angular/core';

import { UserService } from 'app/modules/shared/user/user.service';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NavigationService } from 'app/modules/shared/navigation/navigation.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';

export const AppResolver = () => {
    const userService = inject(UserService);
    const messagesService = inject(MessagesService);
    const navigationService = inject(NavigationService);
    const notificationsService = inject(NotificationsService);
    const quickChatService = inject(QuickChatService);
    const shortcutsService = inject(ShortcutsService);
    
    return forkJoin([
        userService.getMyProfile(),
        navigationService.get(), 
        messagesService.getAll(), 
        notificationsService.getAll(), 
        quickChatService.getChats(), 
        shortcutsService.getAll()
    ]);
};
