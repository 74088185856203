import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Environment } from 'environments/environment';

@Injectable()
export abstract class ApiBaseService {
    protected _httpClient = inject(HttpClient);

    protected get baseUrl(): string {
        return Environment.baseUrl;
    }

    protected get defaultFormHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
            'Accept': 'application/json; charset=utf-8',
        });
    }

    protected get defaultJsonHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json; charset=utf-8',
        });
    }
}
