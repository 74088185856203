import { Subscription, filter } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { SortPageState, createSortState } from 'app/modules/shared/sort-page-state/sort-page-state';
import { SortDirection } from '@angular/material/sort';

@Injectable({ providedIn: 'root' })
export class RouteHistoryService implements OnDestroy {
    private _subscriptions: Subscription[] = [];
    private _savedState: SortPageState;
    private _history = [];

    constructor(private _router: Router) {}

    public enable(): void {
        this._subscriptions.push(
            this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this._history = [...this._history.slice(-1), urlAfterRedirects];
            })
        );
    }

    public get currentUrl(): string {
        return this._history[this._history.length - 1];
    }

    public clearState(): void {
        this._savedState = undefined;
    }

    public getState(): SortPageState {
        return this._savedState;
    }

    public setState(sortColumn: string, sortDirection: SortDirection, pageSize: number, pageIndex: number): void {
        this._savedState = new SortPageState(this.currentUrl, createSortState(sortColumn, sortDirection), pageSize, pageIndex);
    }

    public restoreSortPageState(): boolean {
        try {
            if (this.urlMatchesSavedState()) {
                return true;
            } else {
                this.clearState();
                return false;
            }
        } catch (error) {
            console.error(error);
            this.clearState();
            return false;
        }
    }

    private urlMatchesSavedState(): boolean {
        return this._savedState?.url == this.currentUrl;
    }

    public ngOnDestroy() {
        this._subscriptions.forEach((subscription) => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }
}
