export class EnvironmentBase {
    static getGoogleIdConfiguration(callback: (response: google.accounts.id.CredentialResponse) => void): google.accounts.id.IdConfiguration {
        return {
            client_id: '724807927149-icpa3osm3vf8ffpta91te2jj8kp3e17v.apps.googleusercontent.com',
            callback: callback,
            context: 'signin',
        };
    }

    static getGoogleGsiButtonConfiguration(): google.accounts.id.GsiButtonConfiguration {
        return {
            logo_alignment: 'left',
            shape: 'pill',
            size: 'large',
            text: 'signin_with',
            theme: 'outline',
            type: 'standard',
            width: 320,
        };
    }
}
