import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { User } from 'app/modules/shared/user/user.type';
import { ApiResponse } from 'app/modules/shared/api/api-response.type';
import { ApiBaseService } from 'app/modules/shared/api/api-base.service';
import { AuthenticationService } from 'app/modules/shared/authentication/authentication.service';
import { UserProfile } from 'app/modules/shared/user/user-profile.type';
import { AccessToken } from '../authentication/access-token.type';

@Injectable({ providedIn: 'root' })
export class UserService extends ApiBaseService {
    private readonly _userSubject = new BehaviorSubject<User>(null);
    private readonly _userProfileSubject = new BehaviorSubject<UserProfile>(null);

    public constructor(private _authenticationService: AuthenticationService) {
        super();
        this._authenticationService.accessToken$.subscribe((accessToken: string) => {
            if (accessToken) {
                this.getMyProfile();
            } else {
                this._userSubject.next(null);
                this._userProfileSubject.next(null);
            }
        });
    }

    public get user$(): Observable<User> {
        return this._userSubject.asObservable();
    }

    public get userProfile$(): Observable<UserProfile> {
        return this._userProfileSubject.asObservable();
    }

    public getMyProfile(): Observable<User> {
        return this._httpClient.get(`${this.baseUrl}/securityusers/me`).pipe(
            map((response: ApiResponse<User>) => {
                const user = { ...response.data, status: 'online', avatar: 'assets/images/avatars/male-01.jpg' };
                const userProfile = <UserProfile>{ id: user.id, type: user.type, firstName: user.firstName, lastName: user.lastName, email: user.email };
                this._userProfileSubject.next(userProfile);
                this._userSubject.next(user);
                return user;
            })
        );
    }

    public updateProfile(userProfile: UserProfile): Observable<void> {
        return this._httpClient.put(`${this.baseUrl}/securityusers/updateuserprofile`, userProfile).pipe(
            map((response: ApiResponse<AccessToken>) => {
                this._userProfileSubject.next({ ...this._userProfileSubject.value, firstName: userProfile.firstName, lastName: userProfile.lastName });
                this._userSubject.next({ ...this._userSubject.value, firstName: userProfile.firstName, lastName: userProfile.lastName });
                this._authenticationService.setAccessToken(response.data.accessToken);
                return;
            })
        );
    }

    public updateStatus(status: string): void {
        this._userSubject.next({ ...this._userSubject.value, status });
    }
}
