import { Route } from '@angular/router';

import { AppResolver } from 'app/app.resolvers';
import { LayoutComponent } from './layout/layout.component';
import { AuthenticationGuard } from 'app/modules/shared/authentication/authentication.guard';

export const appRoutes: Route[] = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthenticationGuard],
        canActivateChild: [AuthenticationGuard],
        resolve: { initialData: AppResolver },
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('app/modules/dashboard/dashboard.routes'),
            },
            { path: 'redirect', redirectTo: 'dashboard', pathMatch: 'full' },
        ],
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthenticationGuard],
        canActivateChild: [AuthenticationGuard],
        data: { layout: 'empty' },
        children: [
            {
                path: 'sign-out',
                loadChildren: () => import('app/modules/authentication/sign-out/sign-out.routes'),
            },
            {
                path: 'unlock-session',
                loadChildren: () => import('app/modules/authentication/unlock-session/unlock-session.routes'),
            },
        ],
    },
    {
        path: '',
        component: LayoutComponent,
        data: { layout: 'empty' },
        children: [
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/authentication/sign-in/sign-in.routes'),
            },
            {
                path: 'email-sent',
                loadChildren: () => import('app/modules/authentication/email-sent/email-sent.routes'),
            },
            {
                path: 'reset-password',
                loadChildren: () => import('app/modules/authentication/reset-password/reset-password.routes'),
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/authentication/forgot-password/forgot-password.routes'),
            },
        ],
    },
    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];
