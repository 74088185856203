import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { RouteHistoryService } from 'app/modules/shared/route-history/route-history.service';
@Component({
    standalone: true,
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterOutlet],
})
export class AppComponent {
    constructor(private _routeHistoryService: RouteHistoryService) {
        this._routeHistoryService.enable();
    }

    public get title(): string {
        return 'AquaTrack-Web';
    }
}
