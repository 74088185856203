import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { BooleanInput } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { UserService } from 'app/modules/shared/user/user.service';
import { User } from 'app/modules/shared/user/user.type';

@Component({
    standalone     : true,
    selector       : 'user',
    exportAs       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports        : [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule]
})
export class UserComponent implements OnInit, OnDestroy {
    static ngAcceptInputType_showAvatar: BooleanInput;
    @Input() showAvatar: boolean = true;
    private _subscription: Subscription;
    user: User = null;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _userService: UserService,
        private _router: Router
    ) { }

    ngOnInit(): void {
        this._subscription = this._userService.user$.subscribe((user: User) => 
            {
                this.user = user;
                this._changeDetectorRef.markForCheck();
            });
    }

    updateUserStatus(status: string): void {
        if (!this.user) return;
        this._userService.updateStatus(status)
    }
    
    showProfile(): void {
            this._router.navigate(['/dashboard/security/users/profile']);
    }

    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    ngOnDestroy(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }
}
