import { of } from 'rxjs';
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from 'app/modules/shared/authentication/authentication.service';

export const AuthenticationGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const authenticationService: AuthenticationService = inject(AuthenticationService);
    const router: Router = inject(Router);

    if (!authenticationService.isAuthenticated) {
        const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
        const urlTree = router.parseUrl(`sign-in?${redirectURL}`);
        return of(urlTree);
    }

    return of(true);
};
