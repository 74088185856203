export class SortPageState {
    url: string;
    sort: SortState;
    pageSize: number;
    pageIndex: number;

    constructor(url: string, sort: SortState, pageSize: number, pageIndex: number) {
        this.url = url;
        this.sort = sort;
        this.pageSize = pageSize;
        this.pageIndex = pageIndex;
    }
}
export type SortDirection = '' | 'asc' | 'desc';

export type SortState = {
    column: string;
    direction: SortDirection;
};

export const createSortState = (column: string, direction: SortDirection): SortState => <SortState>{ column, direction };
