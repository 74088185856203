import { NgFor, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { LangDefinition, TranslocoService } from '@ngneat/transloco';
import { SubscribableComponent } from 'app/modules/shared/subscribable/subscribable.component';
export type AvailableLang = LangDefinition | string;
@Component({
    standalone     : true,
    exportAs       : 'languages',
    selector       : 'languages',
    templateUrl    : './languages.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports        : [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor],
})
export class LanguagesComponent extends SubscribableComponent implements OnInit {
    private _availableLanguages: LangDefinition[];
    private _activeLanguage: string;
    private _flagCodes: any;

    constructor(
        private _translocoService: TranslocoService,
        private _fuseNavigationService: FuseNavigationService,
    ) {
        super();
    }

    public get availableLanguages(): LangDefinition[] { return this._availableLanguages; }
    public get activeLanguage(): string { return this._activeLanguage; }

    public ngOnInit(): void {
        // Set the country iso codes for languages for flags
        this._flagCodes = {
            'en': 'us',
            'tr': 'tr',
        };

        // Get the available languages from transloco
        this._availableLanguages = this._translocoService.getAvailableLangs().map((language: AvailableLang) => {
            if (typeof language === 'string') {
                return {
                    id: language,
                    label: language.toUpperCase()
                };
            } else {
                return language;
            }
        });

        // Subscribe to language changes
        this.subscriptions.push(
            this._translocoService.langChanges$.subscribe((activeLanguage) =>
            {
                this._activeLanguage = activeLanguage;
                this._updateNavigation(activeLanguage);
            })
        );
    }

    public getActiveFlag(language: string): string { 
        return this._flagCodes[language];
    }

    public setActiveLanguage(lang: string): void {
        this._translocoService.setActiveLang(lang);
    }

    public trackActiveLanguageByFn(index: number, item: LangDefinition): string {
        return item.id || index.toString();
    }

    private _updateNavigation(language: string): void {
        const navigationComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        if (!navigationComponent) return null;
    }
}
