import { Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-subscribable',
    template: ``,
    imports: [],
})
export class SubscribableComponent implements OnDestroy {
    protected subscriptions: Subscription[] = [];
    protected busy: boolean = false;

    public get isBusy(): boolean {
        return this.busy;
    }

    protected setBusy(): void {
        this.busy = true;
    }

    protected setReady(): void {
        this.busy = false;
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }
}
