import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';

import { authenticationInterceptor } from 'app/modules/shared/authentication/authentication.interceptor';
import { AuthenticationService } from 'app/modules/shared/authentication/authentication.service';

export const provideAuthentication = (): Array<Provider | EnvironmentProviders> => {
    return [
        provideHttpClient(withInterceptors([authenticationInterceptor])),
        {
            useValue: () => inject(AuthenticationService),
            provide: ENVIRONMENT_INITIALIZER,
            multi: true,
        },
    ];
};
