import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { FuseNavigationItem } from '@fuse/components/navigation';

import { ApiResponse } from 'app/modules/shared/api/api-response.type';
import { NavigationItem } from 'app/modules/shared/navigation/navigation-item.type';
import { Navigation } from 'app/modules/shared/navigation/navigation.type';
import { ApiBaseService } from 'app/modules/shared/api/api-base.service';

@Injectable({ providedIn: 'root' })
export class NavigationService extends ApiBaseService {
    private _navigation: BehaviorSubject<Navigation> = new BehaviorSubject<Navigation>(null);

    public get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    public get(): Observable<Navigation> {
        return this._httpClient.get(`${this.baseUrl}/navigation/newitems`).pipe(
            switchMap((response: ApiResponse<NavigationItem[]>) => {
                const navigation: Navigation = this.mapToNavigation(response.data);
                this._navigation.next(navigation);
                return of(navigation);
            })
        );
    }

    private mapToNavigation(items: NavigationItem[]): Navigation {
        return {
            default: items.map((item) => this.mapToFuseNavigationItem(item)),
            compact: items.map((item) => this.mapToFuseNavigationItem(item)),
            futuristic: items.map((item) => this.mapToFuseNavigationItem(item)),
            horizontal: items.map((item) => this.mapToFuseNavigationItem(item)),
        };
    }

    private mapToFuseNavigationItem(item: NavigationItem): FuseNavigationItem {
        return {
            ...item,
            children: item.children ? item.children.map((child) => this.mapToFuseNavigationItem(child)) : null,
        };
    }
}
